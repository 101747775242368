import React,{useState} from "react";
import {
    slice, concat, 
} from 'lodash';
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Card,Row, Col, Button, Figure, Input, InputGroup, FormControl } from 'react-bootstrap';


export default function App() {
    const data = useStaticQuery(query);
    const {allSanityUpdates:{
        nodes:updates
    }} = data

    //SEARCH INPUT
    const [searchTerm, setSearchTerm] = React.useState("");
    // const [searchResults, setSearchResults] = React.useState([]);

    const handleChange = e => {
        setSearchTerm(e.target.value);
                const tr = updates.filter(updates=> 
            updates.upTitle.toLowerCase().includes(e.target.value.toLowerCase()) ||
            updates.author.toLowerCase().includes(e.target.value.toLowerCase()));
            setList(slice(tr, 0, LIMIT));
    };


    const LENGTH = updates.length;
    const DATA = updates;
    const LIMIT = 4;

    const [showMore,setShowMore] = useState(true);
    const [list,setList] = useState(slice(DATA, 0, LIMIT))
    const [index,setIndex] = useState(LIMIT);
    
    const loadMore = () =>{
        const newIndex =  index + 2;
        const newShowMore =  newIndex < (LENGTH);
        const newList = concat(list, slice(DATA, index, newIndex));
        setIndex(newIndex);
        setList(newList);
        setShowMore(newShowMore);
    }
    // const l = list.reverse();
    return (
        <div className="updates-page">   
        {/* <Container className="up-container">  */}
            <Row style={{padding:"unset"}}>
                <Col className="text-col" xs={12} md={6} lg={6}  >
                    <p className="update-text">Latest updates</p>
                </Col>
                <Col className="input-col" xs={12} md={6} lg={6} >
                {/* <Input
                    icon='search'
                    iconPosition='left'
                    placeholder='Search'
                    value={searchTerm} 
                    onChange={handleChange}
                /> */}
                <InputGroup size="sm" className="mb-3">
                    <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm"  placeholder='Search'
                    value={searchTerm} 
                    onChange={handleChange}/>
                </InputGroup>
                </Col>
            </Row>
            {/* <Row style={{marginRight:"-30px",marginLeft:"-30px"}}>  */}
            <Row>
            { 
            list.map((updates)=>{  
                return <>
                <Col xs={12} md={6} lg={6} className="update-column" style={{padding:"unset"}}>
                    <a href={`/updates/${updates.upSlug}/`}> <Card className={updates.upSlug}   key={updates.id} >
                            <Card.Body>
                                <div className="up-1"> 
                                <img  style={{ width: "100%", height: "100%" }} src={updates.upImage.asset.url} alt="Logo" />
                                {/* <Image className="update-img" fluid={updates.upImage.fluid} alt="Update img"></Image> */}
                                </div>
                                <div className="up-2">  
                                <Card.Text className="update-date">{updates.upDate}</Card.Text>
                                <Card.Title>{updates.upTitle}</Card.Title>
                                {/* <p className="by">By <span className="author">{updates.author}</span> </p> */}
                                <Button href={`/updates/${updates.upSlug}/`} className="tertiary-btn-s">Read more 
                                    <Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png').default}  />
                                    </Figure>
                                </Button>
                                </div>
                        </Card.Body> 
                    </Card> 
                    </a>
                </Col> 
                </>
                })}
    
        </Row>
        <Row><Col md="12">  <p onChange={handleChange} className="load-result">{list.length === 0 ? "No results found" : "" }</p> </Col></Row>
        <Row>
            <Col className="load-more-col">
                {showMore &&   <Button onClick={loadMore} className="loadmore-btn-s">More from updates
                    <Figure className="read-more">
                        <Figure.Image   src={require('../assets/images/load-more.svg').default}  />
                    </Figure>
                </Button> }
            </Col>
            {/* {showMore && <button onClick={loadMore}> Load More </button>} */}
        </Row>
            {
                DATA.map((link) => (<a href={`/updates/${link.upSlug}/`} /> ))
            }
        {/* </Container> */}
        </div>
        
    );
}

export const query = graphql`
{
    allSanityUpdates(sort: {order: DESC, fields: upDate}) {
        nodes {
          id
          upDate(formatString: "DD MMMM YYYY")
          upSlug
          upTitle
          author
          upImage {
            asset {
              url
            }
          }
        }
      }
}
`


