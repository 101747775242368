//MailchimpForm.jsx

import addToMailchimp from "gatsby-plugin-mailchimp"
import Button from "@material-ui/core/Button"
import React from "react"
import { Col, Form, Row } from "react-bootstrap"

export default class MailChimpForm extends React.Component {
    constructor() {
        super()
        this.state = { email: "", result: null }
    }
    _handleSubmit = async e => {
        e.preventDefault()
        const result = await addToMailchimp(this.state.email)
        this.setState({result: result})
    }
    handleChange = event => {
            
        this.setState({ email: event.target.value })
    }

render() {
    return  (
        <div className="newsletter">  
        <Row>

            <Col xs={12} md={5} lg={6} >
                <div className="left-sub"> 
                    <img className="left-sub-img" style={{ maxWidth: "100%", maxHeight: "150px" }}  src={require('../assets/images/tr.png').default} alt="Logo" />
                    <p className="left-sub-text">Our latest updates straight to your inbox</p>
                </div>
            </Col>
            <Col xs={12} md={7} lg={6} >
                <div className="right-sub"> 
                <p className="title">Subscribe to the newsletter</p>
                <form action="https://sogody.us7.list-manage.com/subscribe/post?u=d4d1289ee7ffd0ab85bf61b6d&amp;id=957e2a7dd7" onSubmit={this._handleSubmit}  method="post" id="mc-embedded-subscribe-form">
                    <Form.Row className="align-items-center">
                        <Col  xs={12} md={6} lg={6} >
                        <Form.Control
                            className="mb-2"
                            autoComplete="on"
                            type="email"
                            onChange={this.handleChange}
                            id="inlineFormInput"
                            placeholder="Email address"
                        />
                        </Col>
                        <Col  xs={12} md={6} lg={6} >
                        <Button type="submit" className="btn-newsletter">
                            Subscribe
                        </Button>
                        </Col>
                    </Form.Row>
            </form>
        <p className="mailchimp-result">{this.state.result === null ? "" : this.state.result.msg.split('<a')[0] }</p>
        <p className="info">By submitting this form, you agree to receive promotional messages from Sogody. Unsubscribe any time by clicking the link in our emails.</p>
        </div>
        </Col>
        </Row>
        </div>
        )
    }
}