import React from "react"
import { Row, Col, Container} from 'react-bootstrap';

export default function() {

    return (
        <div className="banner-update">  
            <Container> 
                <Row style={{marginLeft: "-16px"}}>
                    <Col xs={12} md={12} lg={12} className="banner-title">
                        <p className="name">Latest updates on the industry and more</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}



